<template>
  <div id="StudentGrow">
    <div class="info">
      <img src="@/assets/images/scene/safe_banner_bg.png" alt="" />
      <div>
        <h2>应用场景 <span>「</span> 平安校园 <span>」</span></h2>
        <!-- <p>Application Scenario-Safe Campus</p> -->
      </div>
    </div>
    <h2 class="applysc">应用场景</h2>
    <!-- <p>Application Scenario</p> -->
    <div class="a_s">
      <div class="a_s_box">
        <img src="@/assets/images/scene/safe_a_s_n.png" alt="" />
        <div class="content">
          晓羊平安校园综合解决方案，分为区级平台和校级平台两级，由“1个综合数据采集系统、1个大数据校园安全云中心和1套风险管控联动机制”构成。通过全域、全员、全程、全时四个维度的全面防控，从学校的日常安全管理和应急处突管理两条线开展安全防控工作。是以数据驱动的具备广度、时度、深度、细度于一体的校园安全综合解决方案。学校的安全出入场景、师生的考勤请假场景、访客管理场景、家校共育场景等。
        </div>
      </div>
    </div>
    <h2>案例</h2>
    <!-- <p>Case Study</p> -->
    <div class="c_s">
      <h2 class="pc">成都机电工程学校&四川宣汉中学</h2>
      <h2 class="mobile">成都机电工程学校<br />四川宣汉中学</h2>
      <p>
        更全面的平安校园管理，助力校园安全的有力保障。堪比大学规模的省重点高中，通过全方位的安全防护系统建设助力校园安全。
      </p>
      <ul class="c_s_img">
        <li><img src="@/assets/images/scene/safe_c_s1.png" alt="" /></li>
        <li><img src="@/assets/images/scene/safe_c_s2.png" alt="" /></li>
        <li><img src="@/assets/images/scene/safe_c_s3.png" alt="" /></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StudentGrow',
  data() {
    return {}
  },
  mounted() {
    this.$store.commit('setPcHeaderIsTransparent', 'transparent')
  },
}
</script>

<style lang="scss" scoped>
%StudentGrow {
  h1,
  h2,
  h3,
  h4 {
    color: #333;
  }
  width: 100%;
  position: relative;
  background: #f6f5f5;
  @include px2vw('padding-bottom', 140);
  // @include px2vw('height', 1255);
  .info {
    position: relative;
    img {
      width: 100%;
    }
    div {
      text-align: center;
      @include px2vw('width', 880);
      @include px2vw('padding', 18);
      @include px2vw('padding-bottom', 60);
      background: rgba(0, 0, 0, 0.3);
      color: $--color-white;
      text-shadow: 0px 2px 12px rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 37%;
      left: 26%;
      h2 {
        @include px2vw('font-size', 56);
        font-weight: normal;
        margin: 32px 0 0;
        // margin-top: 32px;
        color: $--color-white;
        letter-spacing: 4px;
        span {
          @include px2vw('font-size', 32);
          vertical-align: top;
          font-weight: bold;
        }
        span:nth-child(2) {
          vertical-align: bottom;
        }
      }
      p {
        margin: 0;
        @include px2vw('font-size', 33);
        @include px2vw('margin-top', 25);
        letter-spacing: 1px;
      }
    }
  }
  > h2 {
    font-size: 32px;
    text-align: center;
    margin-top: 40px;
  }
  > p {
    text-align: center;
    font-size: 24px;
    color: #333333;
    position: relative;
    &::after {
      content: ' ';
      width: 66px;
      height: 3px;
      display: inline-block;
      position: absolute;
      left: 48%;
      margin-top: 45px;
      background: $--color-primary;
    }
  }
  .a_s {
    width: 90%;
    max-width: 1320px;
    // height: 400px;
    margin: 50px auto;
    background-color: #f2f2f2;
    // background: #f2f2f2 url('~@/assets/images/scene/safe_a_s.png') no-repeat;
    // background-size: 100% 100%;
    // position: relative;
    // background-position: 15px;
    padding: 50px 0;
    display: flex;
    align-items: center;
    .a_s_box {
      background: #f2f2f2;
      margin: 0 60px;
      // padding: 70px 50px 50px 50px;
      z-index: 2;
      img {
        // @include px2vw('width', 440);
        width: 35%;
        vertical-align: middle;
      }
      .content {
        margin-left: 5%;
        display: inline-block;
        width: 60%;
        line-height: 32px;
        font-size: 18px;
        text-align: justify;
        color: #666;
        vertical-align: middle;
      }
    }
  }
  .c_s {
    width: 90%;
    max-width: 1320px;
    margin: 0 auto;
    h2 {
      text-align: center;
      font-size: 40px;
      font-weight: normal;
      margin-top: 50px;
      margin-bottom: 50px;
    }
    p {
      line-height: 32px;
      font-size: 18px;
      text-align: justify;
      color: #666;
      // width: ;
    }
    .c_s_img {
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;
      @include px2vw('margin-top', 90);

      li {
        width: 30%;
        img {
          width: 100%;
        }
      }
    }
  }
}
.view-pc > #StudentGrow {
  @extend %StudentGrow;
  padding-bottom: 100px;
  > h2 {
    font-size: 36px;
  }
  h2.applysc {
    margin: 50px auto;
  }
  .c_s {
    &_img {
      margin-top: 50px !important;
    }
  }
  @media screen and (max-width: 1100px) {
    .a_s {
      width: calc(90% - 120px);
    }
    .c_s {
      width: 90%;

      h2 {
        margin-top: 50px;
      }
      .c_s_img {
        li {
          width: 100%;
          margin: 0 auto;
        }
      }
    }
  }
  @media (min-width: 570px) and(max-width:1440px) {
    .c_s {
      h2 {
        margin-top: 40px;
        margin-bottom: 20px;
      }
    }
  }
}

.view-mobile > #StudentGrow {
  @extend %StudentGrow;
  // @include px2vw('height', 3000);
  > h2 {
    font-size: 22px !important;
  }
  .info {
    margin-top: 44px;
    height: 153px;
    overflow: hidden;
    display: flex;
    div {
      width: 100vw;
      height: 75%;
      padding: 10% 0 0;
      // padding-top: 10%;
      top: 0;
      left: 0;
      h2 {
        font-size: 22px;
        margin-top: 15px;
      }
      p {
        font-size: 10px;
      }
    }
  }
  > p {
    font-size: 14px;

    &::after {
      left: 40% !important;
      margin-top: 35px;
    }
  }
  .student-grow-box {
    margin-top: 40px;
  }
  .a_s {
    background: #f2f2f2;
    padding: 0 20px;
    width: calc(90% - 40px);
    height: 100%;
    margin: 30px 5% 0;
    // margin-bottom: 0;
    .a_s_box {
      flex-wrap: wrap;
      width: 90%;
      padding: 30px 20px;
      margin: 0 !important;
      img {
        width: 100%;
      }
      .content {
        font-size: 14px;
        line-height: 28px;
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }
  .c_s {
    width: 90%;
    margin: 0 auto;
    h2 {
      margin-top: 40px;
      font-size: 24px;
      margin-bottom: 25px;
    }
    p {
      font-size: 14px;
      line-height: 28px;
      text-align: justify;
      margin-bottom: 30px;
    }
    .c_s_img {
      margin-bottom: 0;
      li {
        width: 100%;
        margin: 0 auto 30px;
        // margin-top: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    > p {
      &::after {
        left: 45%;
        margin-top: 35px;
      }
    }
  }
}
</style>
